import Divider from "../../Components/Divider/Divider";
import "./Proposition.css";
import ClockImg from "../../assets/imgs/Clock.avif";
import PointerImg from "../../assets/imgs/Pointer.avif";
import LocationImg from "../../assets/imgs/Location.avif";
import MoveImg from "../../assets/imgs/Move.avif";
export default function Proposition() {
  return (
    <div className="Proposition-Container Container">
      <Divider />
      <p className="Section-Header">Our Value Proposition</p>
      <div className="Cards-Container">
        <div className="Card Container" id="Fast">
          <img src={ClockImg} className="Card-Image" alt="" />
          <h3>Fast</h3>
          <p>
            Super Fast scanning that can reach 100m
            <sup>2</sup> per minute
          </p>
        </div>
        <div className="Card Container" id="Accurate">
          <img src={PointerImg} className="Card-Image" alt="" />
          <h3>Accurate</h3>
          <p>Up to 5mm accuracy for object at distance of 7 meters</p>
        </div>
        <div className="Card Container" id="Autonomous">
          <img src={LocationImg} className="Card-Image" alt="" />
          <h3>Autonomous</h3>
          <p>
            It can be used as handheld or to be installed on autonomous drones
          </p>
        </div>
        <div className="Card Container" id="Easy">
          <img src={MoveImg} className="Card-Image" alt="" />
          <h3>Easy</h3>
          <p>Anyone can use it without any specific qualifications</p>
        </div>
      </div>
    </div>
  );
}
