import "./About.css";
import BigLogo from "../../assets/imgs/BigLogo.avif";
export default function About() {
  return (
    <div className="About-Container" id="About">
      <img src={BigLogo} alt="" />
      <div className="Container">
        <div>
          <p className="Section-Header">About Holoarch</p>
          <p>
            We are developers of 3D scanners and LiDAR systems for many fields
            such as Real State, Construction, Inspection.
          </p>
        </div>
        <p>
          Holoarch was awarded the Seal of Excellence from the European
          Commission.
        </p>
        <div>
          <p>Contact us:</p>
          <a href="mailto:zahi@holoarch.tech">zahi@holoarch.tech</a>
          <a href="tel:0509558814">+972 509558814</a>
          <a href="https://www.linkedin.com/company/19032665">LinkedIn</a>
        </div>
      </div>
    </div>
  );
}
