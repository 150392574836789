import "./Header.css";
import Navbar from "../../Components/Navbar";
import DroneImg from "../../assets/imgs/Drone.avif";
export default function Header() {
  return (
    <div className="Header-Container Container">
      <Navbar />
      <div className="Header-Content">
        <div className="Text-Container">
          <h2>Autonomous 3D Mapping Scanner</h2>
          <p>
            At Holoarch we are able to generate highly accurate 3D maps of
            indoor/outdoor, built and natural environments, using the associated
            high quality sensor trajectory data. Our 3D mapping technology
            allows direct digitalisation of real 3D landscapes into information
            that can be utilised for analysis and inspection.
          </p>
        </div>
        <img src={DroneImg} alt="" />
      </div>
    </div>
  );
}
