import "./Navbar.css";
import LogoImg from "../assets/imgs/HoloarchLogo.avif";
export default function Navbar() {
  return (
    <nav className="Navbar">
      <img src={LogoImg} alt="" className="Logo" />
      <a href="#Applications">Applications</a>
      <a href="#Demo">Demo</a>
      <a href="#About">About</a>
    </nav>
  );
}
