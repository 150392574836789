import "./Applications.css";
import Divider from "../../Components/Divider/Divider";
import Card from "../../Components/Card/Card";
import IndoorRender from "../../assets/imgs/IndoorRender.avif";
import OutdoorRender from "../../assets/imgs/OutdoorRender.avif";
import Substation from "../../assets/imgs/Substation.avif";
import Turbine from "../../assets/imgs/Turbine.avif";
export default function Applications() {
  return (
    <div className="Applications-Container Container" id="Applications">
      <Divider />
      <p className="Section-Header">Applications</p>
      <div className="Cards-Container">
        <Card
          Title="Indoor"
          Text="Holoarch is able to scan closed places like buildings, rooms, tunnels.. using indoor navigation where there is no GPS coverage signal. our scanner is equipped with ultrasonic sensors for position detection."
          ImgSrc={IndoorRender}
        />
        <Card
          Title="Outdoor"
          Text="It creates accurate fully textured 3D models from photographs and can be used to capture a range of subjects from the smallest objects to entire landscapes. From this data we can create 3D Models, videos and orthoimages (scaled images with no distortion or perspective), which allow for more accurate and complete interpretative site drawings than ever before."
          ImgSrc={OutdoorRender}
          ClassName="Outdoor"
        />
      </div>
      <div className="Cards-Container">
        <Card
          Title="Risk Areas"
          Text="Holoarch Scanner can be installed on robotic rovers to access risk areas like High-Voltage Power rooms, Factories, contaminated areas.. autonomously or driven remotely"
          ImgSrc={Substation}
        />
        <Card
          Title="High Building"
          Text="Installing our scanner on a drone gives the user the ability to reach inaccessible areas such as high ceiling, tall building, power wind turbine.."
          ImgSrc={Turbine}
        />
      </div>
    </div>
  );
}
