import "./Demo.css";
import Divider from "../../Components/Divider/Divider";

export default function Demo() {
  return (
    <div className="Demo-Container Container" id="Demo">
      <Divider />
      <p className="Section-Header">Demo Video</p>
      <iframe
        width="640"
        height="360"
        src="https://www.youtube.com/embed/XEO9cf6GDIk"
        title="Holoarch Autonomous 3D Lidar Drone Scanner"
        allow="accelerometer; autoplay; clipboard-write; encrypted-media; gyroscope; picture-in-picture; web-share"
        allowFullScreen
      ></iframe>
    </div>
  );
}
