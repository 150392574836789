import "./Card.css";
export default function Card({
  Title,
  Text,
  ImgSrc,
  ClassName = "",
}: PropTypes) {
  return (
    <div className={`Card-Template Container ${ClassName}`}>
      <h3>{Title}</h3>
      <p>{Text}</p>
      <img src={ImgSrc} alt="" className="Card-Template-Image" />
    </div>
  );
}
interface PropTypes {
  Title: string;
  Text: string;
  ImgSrc: string;
  ClassName?: string;
}
