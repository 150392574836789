import React from "react";
import "./App.css";
import Header from "./Sections/Header/Header";
import Proposition from "./Sections/Proposition/Propostition";
import Applications from "./Sections/Applications/Applications";
import Demo from "./Sections/Demo/Demo";
import About from "./Sections/About/About";

function App() {
  return (
    <div className="App">
      <Header />
      <Proposition />
      <Applications />
      <Demo />
      <About />
      <div className="Footer">Holoarch Technologies</div>
    </div>
  );
}

export default App;
